.hrp-overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20000;
    background-color: transparent;
    cursor: wait;
}
.c_i_center {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.burger {
    display: none;
}
a.active {
    border-radius: 5px;
    background-color: rgba(53, 218, 62, 0.562);
}
.nav {
    padding: 20px;
    border-radius: 5px;
    color: white;
    transition: all 0.3s;
    text-decoration: none;
}
.nav2 {
    border-radius: 5px;
    color: blue;
    transition: all 0.3s;
    text-decoration: none;
}
.nav:hover {
    text-decoration: none;
    background-color: rgba(53, 218, 62, 0.562);
}
.nav_wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
@media only screen and (max-width: 600px) {
    .burger {
        display: flex;
    }
    .nav_wrapper {
        display: none;
    }
}
.css-1usso8h-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center;
}
@media only screen and (max-width: 600px) {
    .Popup {
        overflow-y: scroll;
        width: 90% !important;
        height: fit-content !important;
    }
}
.MuiDataGrid-root MuiDataGrid-root--densityStandard MuiDataGrid-withBorderColor css-1usso8h-MuiDataGrid-root {
    display: none !important;
}
.right_left {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.login_social_wrapper {
    display: flex;
    flex-direction: column !important;
}
.right-left {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.css-duaqfz .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center !important;
}

.MuiTablePagination-displayedRows css-1chpzqh,
p {
    margin-bottom: 0rem !important;
}
.colview_pass {
    position: relative;
}
.view_icon {
    cursor: pointer;
    position: absolute;
    top: 55px;
    right: 10px;
}
.css-1tw2i9j-MuiGrid-root > .MuiGrid-item {
    width: 100%;
}
.css-7aty7k > .MuiGrid-item {
    width: 100%;
}
.input_div {
    position: relative;
}
.input_div svg {
    position: absolute;
    top: 33px;
    right: 10px;
    cursor: pointer;
}
.input_div input {
    padding-right: 44px;
}
.pointer {
    cursor: pointer;
    display: flex;
    /* background-color: red; */
    /* justify-content: space-between; */
    align-items: center;
}
.css-tzssek-MuiSvgIcon-root {
    font-size: 47px !important;
    margin-right: 10px;
    margin-bottom: -8px;
}
.search_location_input {
    height: 100% !important;
}
@media only screen and (max-width: 700px) {
    .search_location_input {
        height: 55px !important;
    }
    .modal_box {
        width: 90% !important;
    }
    .margin_left {
        margin-left: 0;
    }
}
.margin_left {
    margin-left: 20px !important;
}
@media only screen and (max-width: 600px) {
    .view_wrapper {
        flex-direction: column;
    }
}
.DataGrid_wrapper {
    min-height: 640px !important;
    max-height: fit-content !important;
}
.css-6h1dhi-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center !important;
}

/* skill */

.card-brd {
    min-height: 200px !important;
    max-height: fit-content !important;
    box-shadow: rgba(139, 139, 139, 0.45) 0px 4px 4px -2px, rgba(101, 101, 101, 0.48) 0px 0px 1px 1px;
    border-radius: 4px;
    padding: 15px;
}
.card-brd span {
    font-size: 13pt;
    padding: 0 12px;
    line-height: 30px;
    cursor: pointer;
    height: 33px;
    user-select: none;
}
.bi-trash {
    font-size: 21.5px !important;
    margin: 5px -4px 0 3px;
}
.bi-trash:hover {
    color: rgb(255, 34, 78);
}
#lbl-list {
    display: none;
    font-size: 13px;
    margin-left: 10px;
}
